import React from "react"
import { useRouter } from "next/router"
import { useAuth, login } from "../../../context/authContext"
import Link from 'next/link';
import { useToast } from "../../../context/toastContext";

const initialState = {
  email: "",
  password: "",
}

const SignIn = ({}) => {
  const router = useRouter();
  const [state, setState] = React.useState(initialState);
  const { dispatch } = useAuth();
  const { query } = useRouter();
  const { from } = query;

  const toast = useToast();

  const handleClick = async () => {
    try {
      await login(dispatch, state.email, state.password)
      from ? router.push(from.toString()) : router.push("/")
    } catch (err) {
      if(err.status === 401) {
        if (err.data?.error === "Invalid password") {
          toast(
            "error",
            <div>
              <p>Sorry, you have entered the wrong username/password combination. Please try again.</p>
              <p>You may reset your password using the <Link href="/users/forgot_password">link</Link> below.</p>
              <p>Email <a href="mailto:info@vinousmedia.com">info@vinous.com</a> if you need further assistance.</p>
            </div>
          );
        } else {
          toast("error", "Sorry, that login is invalid");
        }
      }
      else {
        toast("error", "Oops, an error has occurred");
      }
    }
  }

  const handleEnter = async (e) => {
    const evt = e || window.event
    const charCode = evt.keyCode || evt.which
    if (charCode == 13) {
      handleClick()
    }
  }

  return (
    <div
      className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      onKeyPress={(e) => handleEnter(e)}
    >
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in
          </h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm"
                placeholder="Email address"
                onChange={(e) => {
                  setState({ ...state, email: e.target.value })
                }}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red focus:border-red focus:z-10 sm:text-sm"
                placeholder="Password"
                onChange={(e) => {
                  setState({ ...state, password: e.target.value })
                }}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 text-red focus:ring-red border-gray-300 rounded"
              />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>
            <div className="text-sm">
              <ul className="font-medium text-red hover:text-red">
                <li>
                  <Link href="/users/forgot_password">
                    Forgot your password?
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <button
              type="button"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red hover:bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red"
              onClick={() => handleClick()}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {/* <!-- Heroicon name: solid/lock-closed --> */}
                <svg
                  className="h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign in
            </button>
          </div>
          <div>
            <div className="text-center font-medium text-red hover:text-red">
              <Link href="/users/plan">
                  Subscribe
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignIn;
