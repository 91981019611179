import React from "react"

import Layout from "../../components/layout/Layout/Layout"
import Header from "../../components/layout/Header/Header"
import SignIn from "../../components/layout/SignIn/SignIn"

export default function SignInPage() {
  return (
    <Layout>
      <Header></Header>
      <SignIn></SignIn>
    </Layout>
  );
}
